import { render, staticRenderFns } from "./ProjectCardCpt.vue?vue&type=template&id=0c623568&scoped=true&"
import script from "./ProjectCardCpt.vue?vue&type=script&lang=ts&"
export * from "./ProjectCardCpt.vue?vue&type=script&lang=ts&"
import style0 from "./ProjectCardCpt.vue?vue&type=style&index=0&id=0c623568&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c623568",
  null
  
)

export default component.exports