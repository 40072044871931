
























































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";
import { ProjectModel } from "@/core/models";
import { CompanyCardCpt } from "../../company";
import { ProjectHistoryCpt, ProjectKeyDetailsCpt } from "./";
import { ProjectService } from "@/core/services";
import TrackingNotesCpt from "@/modules/tracking/components/TrackingNotesCpt.vue";
import { ProjectTagListCpt } from "@/modules/tags";
import ProjectDetailCpt from "./ProjectDetailCpt.vue";
import ProjectHeaderCpt from "./ProjectHeaderCpt.vue";
import { MapView } from "@/modules/map";
import { MapEvents } from "@/modules/map/MapView.vue";

interface ITabPane extends Vue {
  name: string;
}

@Component({
  components: {
    ProjectHistoryCpt: () => Promise.resolve(ProjectHistoryCpt),
    CompanyCardCpt: () => Promise.resolve(CompanyCardCpt),
    TrackingNotesCpt: () => Promise.resolve(TrackingNotesCpt),
    ProjectTagListCpt: () => Promise.resolve(ProjectTagListCpt),
    ProjectDetailCpt: () => Promise.resolve(ProjectDetailCpt),
    ProjectHeaderCpt: () => Promise.resolve(ProjectHeaderCpt),
    ProjectKeyDetailsCpt: () => Promise.resolve(ProjectKeyDetailsCpt),
    MapView: () => Promise.resolve(MapView),
  },
})
export default class ProjectTabbedDetailsCpt extends AppVue {
  @Prop() model: ProjectModel;
  @Prop({ default: true }) loading: boolean;

  history: any[] = [];
  historyLoading: boolean = false;
  currentProjectID: number = 0;
  activeTab: string = "companies";

  @Watch("model.isWatched")
  goToTracking(newValue: any, oldValue: any) {
    if (!!newValue && oldValue === false) {
      this.activeTab = "tracking";
    }
  }

  @Watch("model.projectId")
  async updateProject(newValue: any) {
    this.currentProjectID = newValue;
    this.history = [];

    await this.tabClick({ name: this.activeTab } as ITabPane);
  }
  async created() {
    this.currentProjectID = parseInt(this.$route.params.projectId, 10);
    if (this.$isMobile()) {
      this.activeTab = "General";
    }
  }

  async tabClick(tab: ITabPane) {
    if (tab.name === "Map") {
      this.$nextTick(() => {
        this.$eventHub.$emit(MapEvents.AddMarkerCollection, [
          {
            resultId: this.model.projectId,
            data: this.model,
            text: this.model.title,

            latLong: {
              lat: this.model.location[0],
              lng: this.model.location[1],
            },
            pin: true,
            zoom: 10,
          },
        ]);
      });
    }
    if (tab.name === "fourth" && this.history.length === 0) {
      this.historyLoading = true;
      try {
        this.history = await ProjectService.getProjectHistory(this.currentProjectID);
      } finally {
        this.historyLoading = false;
      }
    }
  }
}
