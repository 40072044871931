













































import { Component, Vue } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";
import { ProjectDetailCpt, ProjectTabbedDetailsCpt } from ".";
import { ProjectModel } from "@/core/models";
import { ProjectService, WatcherService, IconService } from "@/core/services";
import { MapEvents, LocationObject } from "../map/MapView.vue";
import { BreadCrumbsCpt } from "@/core/components";
import { routeStack } from "@/core/services/routeStack.service";
import { CompanyCardCpt } from "../company";
import { ProjectKeyDetailsCpt } from "./components";

@Component({
  components: {
    ProjectDetailCpt: () => Promise.resolve(ProjectDetailCpt),
    ProjectTabbedDetailsCpt: () => Promise.resolve(ProjectTabbedDetailsCpt),
    BreadCrumbsCpt: () => Promise.resolve(BreadCrumbsCpt),
    CompanyCardCpt: () => Promise.resolve(CompanyCardCpt),
    ProjectKeyDetailsCpt: () => Promise.resolve(ProjectKeyDetailsCpt),
  },
  async beforeRouteEnter(to, from, next) {
    // before loading the route, check that the cache is built
    await WatcherService.projectList(0);
    await WatcherService.companyList(0);
    next();
  },
  async beforeRouteUpdate(to, from, next) {
    const c = this as ProjectDetailView;
    try {
      c.currentProjectID = parseInt(to.params.projectId, 10);
      if (!c.currentProjectID) {
        next();
        return;
      }
      c.loading = true;
      c.model = await ProjectService.getProjectDetails(c.currentProjectID);
      c.updateTitle(c.model.title);
      routeStack.push(this.$route);
      routeStack.addTitle(to, c.model.title);
      if (c.model.location) {
        const marker = {
          resultId: c.currentProjectID,
          data: c.model,
          latLong: {
            lat: c.model.location[0],
            lng: c.model.location[1],
          },
          zoom: 10,
          pin: true,
          text: c.model.title,
        };
        this.$eventHub.$emit(MapEvents.Focus, marker);
      } else {
        this.$eventHub.$emit(MapEvents.Center);
      }
    } finally {
      c.loading = false;
    }
    next();
  },
})
export default class ProjectDetailView extends AppVue {
  loading: boolean = false;
  currentProjectID = 0;
  selectedTab = "";
  model: ProjectModel = {} as ProjectModel;
  mounted() {}
  async created() {
    this.$eventHub.$on("MarkerClicked", this.markerClick);

    this.selectedTab = this.$route.params.selectedTab;
    this.currentProjectID = parseInt(this.$route.params.projectId, 10);
    this.loading = true;
    this.model = await ProjectService.getProjectDetails(this.currentProjectID);
    super.updateTitle(this.model.title);
    routeStack.push(this.$route);
    routeStack.addTitle(this.$route, this.model.title);

    // Stop processing if user cannot access this project
    if (this.model.isLimited) {
      this.loading = false;
      return;
    }

    if (this.model.location) {
      const marker = {
        resultId: this.currentProjectID,
        data: this.model,
        latLong: {
          lat: this.model.location[0],
          lng: this.model.location[1],
        },
        zoom: 10,
        pin: true,
        text: this.model.title,
      };
      this.$eventHub.$emit(MapEvents.Focus, marker);
    } else {
      this.$eventHub.$emit(MapEvents.Center);
    }

    this.loading = false;
  }
  destroyed() {
    this.$eventHub.$off("MarkerClicked", this.markerClick);
  }

  async markerClick(item: LocationObject) {
    const proj = item.data as ProjectModel;
    if (proj.projectId.toString() !== this.$route.params.projectId) {
      this.$router
        .push({
          name: "Project",
          params: {
            projectId: proj.projectId.toString(),
          },
        })
        .catch(() => {});
    }
  }
}
