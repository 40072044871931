



































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import _ from "lodash";
import {} from "@/core/services";
import {} from "@/core/components";
import { ProjectModel } from "@/core/models";
import AppVue from "@/AppVue.vue";

@Component({
  components: {},
})
export default class ProjectKeyDetailsCpt extends AppVue {
  @Prop() model: ProjectModel;
  @Prop({ default: true }) loading: boolean;

  get hasLocation() {
    return this.model && this.model.location && this.model.location.length >= 2;
  }
  get latitude() {
    if (this.hasLocation) {
      return this.model.location[0];
    }
    return 0;
  }
  get longitude() {
    if (this.hasLocation) {
      return this.model.location[1];
    }
    return 0;
  }
  get showMetrics() {
    // Only show metrics if at least one of them is not null
    return _.values(this.model.metric).findIndex((obj) => obj != null) !== -1;
  }
  async created() {}

  async mounted() {}
}
