





























import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import BlockIcon from "vue-material-design-icons/BlockHelper.vue";

import { WatcherService, ProjectService, UserForgetProjectService } from "@/core/services";
import { WatchedButton } from "@/core/components";

import AppVue from "@/AppVue.vue";
import { ProjectModel } from "@/core/models";

@Component({
  components: {
    WatchedButton,
    BlockIcon,
  },
})
export default class ProjectActionBarCpt extends AppVue {
  @Prop({ default: false }) loading: boolean;
  @Prop({ default: {} }) model: ProjectModel;
  downloadLoading: boolean = false;
  size = "small";
  watched: boolean = false;
  isForgotten: boolean | undefined = false;

  get isWatched() {
    return this.model.isWatched;
  }

  get isLoading() {
    return this.loading;
  }

  get trackingIcon() {
    if (this.model.isWatched) {
      return "el-icon-star-on";
    }

    return "el-icon-star-off";
  }

  @Watch("model.projectId")
  async projectIdChanged(val: any, oldVal: any) {
    this.isForgotten = await this.exists();
  }

  async exists() {
    if (this.model && this.model.projectId) {
      return await UserForgetProjectService.exists(Number(this.model.projectId));
    }
  }

  async toggleForgetProject(projectId: number) {
    if (this.isForgotten) {
      await UserForgetProjectService.DeleteUserForgetProject(Number(this.model.projectId));
      this.notify("Project remembered", "Project remembered again!", "success");
      this.isForgotten = false;
    } else {
      try {
        await UserForgetProjectService.createUserForgetProject(Number(projectId));
        this.notify("Project forgotten", this.forgetProjectMessage, "success", true);
        this.isForgotten = true;
      } catch (ex: any) {
        if (ex == "Error: Request failed with status code 400") {
          this.notify("Limit reached", this.maxForgottenProjectsReachedMessage, "warning", true);
        } else {
          this.notify("Error", ex, "error", true);
        }
      }
    }
  }

  async downloadProject(projectId: number) {
    try {
      this.downloadLoading = true;
      const filename = `Project_${projectId}.pdf`;
      const result = await ProjectService.exportProject(projectId);
      const url = window.URL.createObjectURL(new Blob([result]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      this.$notify({
        title: "Download completed!",
        type: "success",
        message: `${filename} downloaded successfully`,
      });
    } finally {
      this.downloadLoading = false;
    }
  }
  async toggleWatchList(projectId: number) {
    if (!this.model.isWatched) {
      const watchResult = await WatcherService.watchProject(this.model);
      this.model.isWatched = true;
      this.$notify({
        title: "Tracking",
        message: `Now tracking ${this.model.title}`,
        type: "success",
      });
    } else {
      const unwatchResult = await WatcherService.unwatchProject(projectId);
      this.model.isWatched = false;
      this.$notify({
        title: "Tracking",
        message: `No longer tracking ${this.model.title}`,
        type: "success",
      });
    }
  }
  async created() {
    if (this.$isMobile()) {
      this.size = "mini";
    }
    this.isForgotten = await this.exists();
  }

  async mounted() {}
}
