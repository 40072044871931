import { ProjectDetailView, SocialMediaLandingView } from ".";
import Vue from "vue";
import { MapView } from "../map";
import { routeStack } from "@/core/services/routeStack.service";
import { BreadCrumbsCpt, CompanyResultsNavigationCpt, ProjectResultsNavigationCpt } from "@/core/components";
import { HeaderCpt } from "@/layouts";
import { CompanyDetailView } from "../company";

const rootPath: string = "Project";
export const routes = [
  {
    path: "/Project/:projectId",
    name: "Project",
    components: {
      default: ProjectDetailView,
      map: () => import(/* webpackChunkName: "about" */ "../map/MapView.vue"),
      filters: ProjectResultsNavigationCpt,
      header: HeaderCpt,
    },
    props: {
      filters: (r: any) => {
        return {
          projectId: r.params.projectId,
          breadcrumbs: routeStack.routeStack,
        };
      },
    },
    meta: {
      layout: "map",
      breadCrumb: true,
    },
  },
  {
    path: "/Project/:projectId/Company/:companyId",
    name: "CompanyInProject",
    components: {
      default: CompanyDetailView,
      map: MapView,
      filters: CompanyResultsNavigationCpt,
      header: HeaderCpt,
    },
    props: {
      filters: (r: any) => {
        return {
          projectId: r.params.projectId,
          companyId: r.params.companyId,
          isVisible: true,
          breadcrumbs: routeStack.routeStack,
        };
      },
    },
    meta: {
      layout: "map",
      breadCrumb: true,
    },
  },
  {
    path: "/p/:projectId",
    name: "SocialMediaLandingPage",
    props: {
      filters: (r: any) => {
        return {
          projectId: r.params.projectId,
        };
      },
    },
    meta: { layout: "empty", allowAnonymous: true },
    component: SocialMediaLandingView,
  },
];
