

























import { Component, Vue, Prop } from "vue-property-decorator";

import {} from "@/core/services";
import { SectorIconCpt } from "@/core/components";
import { ProjectFeedbackButtonCpt, ProjectOpenMapButtonCpt } from ".";
import AppVue from "@/AppVue.vue";
import { ProjectModel } from "@/core/models";

@Component({
  components: {
    SectorIconCpt,
    ProjectFeedbackButtonCpt: () => Promise.resolve(ProjectFeedbackButtonCpt),
    ProjectOpenMapButtonCpt: () => Promise.resolve(ProjectOpenMapButtonCpt),
  },
})
export default class ProjectHeaderCpt extends AppVue {
  @Prop({ default: false }) loading: boolean;
  @Prop({ default: {} }) model: ProjectModel;

  get showValue() {
    if (this.model.title && (this.model.title.startsWith("€") || this.model.title.startsWith("£"))) {
      return false;
    }
    return true;
  }
  get hasCategory() {
    return (
      !!this.model &&
      !!this.model.categories &&
      this.model.categories.length > 0 &&
      !!this.model.categories[0] &&
      !!this.model.categories[0].category
    );
  }

  handleClickDefaultMap() {}

  async created() {}

  async mounted() {}
}
