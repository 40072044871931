var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-container',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"element-loading-text":"Loading...","element-loading-spinner":"el-icon-loading"}},[_c('el-main',[(!_vm.$isMobile())?_c('ProjectHeaderCpt',{attrs:{"model":_vm.model}}):_vm._e(),_c('div',{staticClass:"summary"},[_c('ProjectActionBarCpt',{attrs:{"model":_vm.model}}),(_vm.hasCategory)?_c('div',{staticClass:"project-detail"},[_c('div',{staticClass:"icon"},[_c('i',{staticClass:"cis-icon-cabinet"})]),_c('div',{staticClass:"main"},[_c('h3',[_vm._v("Primary sector")]),_c('div',[_vm._v(" "+_vm._s(_vm.model.categories[0].sector)+" "),_c('i',{staticClass:"el-icon-caret-right"}),_vm._v(" "+_vm._s(_vm.model.categories[0].category)+" ")])])]):_vm._e(),_c('ProjectLabeledSectionCpt',{attrs:{"label":"Location","icon":"cis-icon-pin","value":_vm.model.address}}),(!!_vm.model.linkedProjects && _vm.model.linkedProjects.length > 0)?_c('div',{staticClass:"project-detail"},[_c('div',{staticClass:"icon"},[_c('i',{staticClass:"cis-icon-link"})]),_c('div',{staticClass:"main"},[_c('h3',[_vm._v(" Linked projects ")]),_vm._l((_vm.model.linkedProjects),function(project){return _c('div',{key:project.projectId},[_c('router-link',{attrs:{"to":{
                name: 'Project',
                params: { projectId: project.projectId },
              }}},[_vm._v(" "+_vm._s(project.title)+" ")])],1)})],2)]):_vm._e(),(_vm.model.researcher)?_c('div',{staticClass:"project-detail"},[_c('div',{staticClass:"icon"},[_c('i',{staticClass:"cis-icon-user"})]),_c('div',{staticClass:"main"},[_c('h3',[_vm._v("CIS Researcher")]),_c('div',{staticClass:"researcher"},[_c('div',{staticClass:"photo"},[_c('img',{attrs:{"src":_vm.model.researcher.photo}})]),_c('div',{staticClass:"contact"},[_vm._v(" "+_vm._s(_vm.model.researcher.name)+" "),_c('br'),_vm._v(" Do you have questions or require further information on this project? "),_c('a',{attrs:{"href":'mailto:' +
                    _vm.model.researcher.email +
                    '?cc=research@cisireland.com&subject=Project ' +
                    _vm.model.title +
                    ' (' +
                    _vm.model.projectId +
                    ')'}},[_vm._v(" Please contact me. ")])])])])]):_vm._e(),(!!_vm.model.recallDate && !_vm.model.isResearchComplete)?_c('ProjectLabeledSectionCpt',{attrs:{"label":"CIS Next review","icon":"cis-icon-user","value":_vm._f("cisMonth")(_vm.model.recallDate)}}):_vm._e(),(!!_vm.model.isResearchComplete)?_c('ProjectLabeledSectionCpt',{attrs:{"label":"CIS Next review","icon":"cis-icon-user","value":"Research Complete"}}):_vm._e(),_c('ProjectLabeledSectionCpt',{attrs:{"label":"Description","icon":"cis-icon-description","value":_vm._f("b$")(_vm.model.description,'em')}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }