





































import { Component, Vue } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";

// models
import { ProjectModel } from "@/core/models";
import { images } from "@/core/constants";

// components
import { LoginCpt } from "@/modules/auth";
import { FooterCpt } from "@/core/components";

// services
import { ProjectService } from "@/core/services";

@Component({
  components: {
    LoginCpt: () => Promise.resolve(LoginCpt),
    FooterCpt: () => Promise.resolve(FooterCpt),
  },
})
export default class SocialMediaLandingView extends AppVue {
  currentProjectID = 0;
  loading: boolean = false;
  model: ProjectModel = {} as ProjectModel;
  logo = images.cisLogoCropped;
  activeName = "signup";

  mounted() {
    if (!this.$route.query.redirectTo) {
      this.$router.push({
        path: this.$route.path,
        query: {
          redirectTo: `/project/${this.currentProjectID}`,
        },
      });
    }
  }

  async created() {
    this.loading = true;
    this.currentProjectID = parseInt(this.$route.params.projectId, 10);
    this.model = await ProjectService.getAnonymousProjectDetails(this.currentProjectID);
    this.updateTitle(this.model.title);
  }
}
