



















































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import {} from "@/core/services";
import {} from "@/core/components";

import AppVue from "@/AppVue.vue";
import moment from "moment";
import { truncateFilter } from "@/core/filters";
import { b$ } from "@/core/filters/beautifier";
@Component({
  components: {},
})
export default class LabeledSectionCpt extends AppVue {
  @Prop({ default: false }) loading: boolean;
  @Prop({ default: "New label" }) label: string;
  @Prop({ default: "New value" }) value: string;
  @Prop({ default: "key" }) icon: string;
  @Prop({ default: "text" }) type: string;
  @Prop({ default: true }) hideIfEmpty: boolean;
  b$ = b$;
  showFullDescription = false;

  @Watch("value")
  onValueChanged(val: any, oldVal: any) {
    // just rest the showFullDescription when model is updated
    this.showFullDescription = false;
  }

get httpLinkText() {
  const maxlength = 40;
  return (this.value.length > maxlength) ? this.value.slice(0, maxlength - 1) + '…' : this.value;  
}

  get httpLink() {
    if(this.value.startsWith("http")) {
      return this.value;
    }

    return `https://${this.value}`;
  }

  get isLoading() {
    return this.loading;
  }

  get isHidden() {
    return this.hideIfEmpty && !this.value;
  }

  get truncatedText() {
    return this.b$(truncateFilter(this.value, 500), "em");
  }
  get fullText() {
    return this.b$(this.value, "em");
  }
  get isTruncated() {
    return this.value.length > 500;
  }

  async created() {}

  async mounted() {}
}
