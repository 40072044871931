
































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";
import {
  ProjectHeaderCpt,
  ProjectActionBarCpt,
  ProjectLabeledSectionListCpt,
  ProjectSectorCpt,
  ProjectLabeledSectionCpt,
} from ".";
import { ProjectModel } from "@/core/models";
import { b$ } from "@/core/filters/beautifier";
import { IconService } from "@/core/services";

@Component({
  components: {
    ProjectHeaderCpt: () => Promise.resolve(ProjectHeaderCpt),
    ProjectActionBarCpt: () => Promise.resolve(ProjectActionBarCpt),
    ProjectSector: () => Promise.resolve(ProjectSectorCpt),
    ProjectLabeledSectionCpt: () => Promise.resolve(ProjectLabeledSectionCpt),
    ProjectLabeledSectionListCpt: () => Promise.resolve(ProjectLabeledSectionListCpt),
  },
})
export default class ProjectDetailCpt extends AppVue {
  @Prop() model: ProjectModel;
  @Prop({ default: false }) loading: boolean;
  @Watch("model")
  updateResearcher() {
    // add researcher details to model
    this.model.researcher = IconService.getResearcherBySectorName(this.model.categories[0].sector, this.model.county);
  }
  get hasCategory() {
    return !!this.model && !!this.model.categories && this.model.categories.length > 0;
  }
}
