














import { Component, Vue, Prop } from "vue-property-decorator";
import {} from "@/core/services";
import {} from "@/core/components";
import {} from "@/core/models";

import AppVue from "@/AppVue.vue";
import moment from "moment";
interface IHistoryRow {
  dateUpdated: Date;
  time?: string;
  description: string;
}

@Component({
  components: {},
})
export default class ProjectHistoryCpt extends AppVue {
  @Prop({ default: [] }) model: IHistoryRow[];

  get grouped() {
    return groupByDateField(this.model, "dateUpdated");
  }

  async created() {}

  async mounted() {}
}
const groupByDateField = (xs: any[], key: string) => {
  return xs.reduce((rv, x) => {
    const dateK = moment.utc(x[key]);
    const k = dateK.format("DD/MM/YYYY");
    x.time = dateK.format("hh:mm");
    (rv[k] = rv[k] || []).push(x);
    return rv;
  }, {});
};
