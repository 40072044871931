

















































import { Component, Vue, Prop } from "vue-property-decorator";

import {} from "@/core/services";

import AppVue from "@/AppVue.vue";
import { ProjectModel } from "@/core/models";
import { ProjectSectorCpt, ProjectLabeledSectionCpt } from ".";
import { MapEvents } from "../../map/MapView.vue";

@Component({
  components: {
    ProjectSectorCpt: () => Promise.resolve(ProjectSectorCpt),
    ProjectLabeledSectionCpt: () => Promise.resolve(ProjectLabeledSectionCpt),
  },
})
export default class ProjectCardCpt extends AppVue {
  @Prop({ default: false }) loading: boolean;
  @Prop({ default: false }) selected: boolean;
  @Prop({ default: "" }) label: string;
  @Prop({ default: new ProjectModel() }) model: ProjectModel;

  selectProject() {
    this.$emit("projectSelected", this.model);
    if (!this.model || !this.model.location) {
      return;
    }
    this.$eventHub.$emit(MapEvents.Focus, {
      resultId: this.model.projectId,
      data: this.model,
      latLong: {
        lat: this.model.location[0],
        lng: this.model.location[1],
      },
      pin: false,
    });
  }

  async created() {}

  async mounted() {}
}
