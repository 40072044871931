






















import { Component, Vue, Prop } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";

@Component({
  components: {},
})
export default class ProjectOpenMapButtonCpt extends AppVue {
  @Prop({ default: "" }) location: number[];

  get appleMapsLink() {
    return `http://maps.apple.com/?q=${this.location[0]},${this.location[1]}`;
  }
  get googleMapsLink() {
    return `https://www.google.com/maps/search/?api=1&query=${this.location[0]},${this.location[1]}`;
  }
  get bingMapsLink() {
    return `https://www.bing.com/maps/?v=2&cp=${this.location[0]}~${this.location[1]}&lvl=16`;
  }
}
